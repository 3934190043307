/* App.css */
.user-info, .wallet-info {
    border: 1px solid white;
    padding: 5px 10px;
    margin: 0 5px;
    color:white;
}

.user-info button, .wallet-info button {
    margin-top: 5px;
    background-color: red;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
}

.user-info button:hover, .wallet-info button:hover {
    background-color: darkred;
}

.main-app {
    text-align: center;
}

.cta-button {
    padding: 15px;
    border: none;
    border-radius: 12px;
    min-width: 250px;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.connect-wallet-button {
    background: rgb(32, 129, 226);
}

.mint-nft-button {
    background: orange;
}


.link-wallet-button {
    background: green;
}

.collectionWrapper {
    padding: 1% 2% 3% 2%;
    background: #1c1c1c;
    text-align: center;
    background: #000
}

    .collectionWrapper h2 {
        font-size: 225%
    }

.collectionRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    margin-top: 2rem;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto
}

.renderCollection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    margin-top: 2rem;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto
}

.collectionImage {
    margin-right: 20px;
    width: 300px;
    border-radius: 1rem;
    vertical-align: middle;
    border: 4px solid #d94745
}
.traitImage {
    width: auto;
    max-height: 90px; /* This will ensure that the trait images will maintain their aspect ratio but won't exceed the set max-height */
    height: auto;
}

.traitrow {
    margin-bottom: 10px;
    border-radius: 0.5rem;
    vertical-align: middle;
    border: 2px solid rgb(32, 129, 226);
    padding: 2px 2px 2px 2px;
}



.collectionTokenTitle {
    margin-top: 1rem;
    color: #000;
    font-size: 125%
}

.navtextright {
    margin-right:30px;
}


/* Media Queries */
@media(max-width:800px) {
    .renderCollection, .collectionRow {
        grid-template-columns: 1fr;
        gap: 2rem; /* adjust gap if needed for mobile views */
    }
}

@media(min-width:800px) and (max-width:1399px) {
    .renderCollection, .collectionRow {
        grid-template-columns: 1fr 1fr;
    }
}

@media(min-width:1400px) {
    .renderCollection, .collectionRow {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}